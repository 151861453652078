
import { defineComponent } from 'vue'
import { Discount } from '@/shared/interfaces/discount.model'
import { formatDate } from '@/shared/utils/formatDate'

export default defineComponent({
  computed: {
    products () {
      return this.$store.state.products.filter((product: any) => product.prices && product.prices.length > 0)
    },
    trial () {
      return this.$store.state.discounts ? this.$store.state.discounts.find((discount: Discount) => discount.trial_weeks && discount.trial_weeks > 0) : null
    },
    otherDiscounts () {
      return this.$store.state.discounts ? this.$store.state.discounts.filter((discount: Discount) => !discount.trial_weeks) : []
    }
  },
  methods: {
    formatDate (date: Date) {
      return formatDate(date)
    },
    getPrice (product: any, interval: string) {
      return product.prices && product.prices.length > 0 ? product.prices.find((price: any) => price.recurring && price.recurring.interval === interval) : null
    },
    getOtherPrices (product: any) {
      return product.prices ? product.prices.filter((price: any) => price.recurring && price.recurring.interval !== 'month' && price.recurring.interval !== 'year') : []
    }
  },
  mounted () {
    // const script = document.createElement('script')
    // script.setAttribute('async', '')
    // script.src = 'https://js.stripe.com/v3/pricing-table.js'
    // document.head.appendChild(script)
    this.$store.dispatch('getProducts')
  }
})
