import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto p-1 sm:p-1" }
const _hoisted_2 = { class: "mb-2 text-center bg-white p-6 rounded shadow-md md:mb-2" }
const _hoisted_3 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_4 = { class: "text-base mb-4 text-gray-600" }
const _hoisted_5 = { class: "flex flex-wrap items-stretch justify-around gap-2 my-10" }
const _hoisted_6 = {
  key: 0,
  class: "border rounded p-5 shadow-md w-full h-full flex flex-col"
}
const _hoisted_7 = { class: "p-2 text-xl font-bold" }
const _hoisted_8 = { class: "my-5 p-5 text-base text-left border rounded" }
const _hoisted_9 = { class: "font-bold mb-2" }
const _hoisted_10 = {
  key: 0,
  class: "mt-auto"
}
const _hoisted_11 = { class: "font-bold mb-2 text-left text-base" }
const _hoisted_12 = {
  key: 0,
  class: "text-left text-base mb-5"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 1,
  class: "flex items-center gap-2 mb-5 justify-between"
}
const _hoisted_18 = { class: "text-base" }
const _hoisted_19 = { class: "uppercase" }
const _hoisted_20 = { class: "lowercase" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 2,
  class: "flex items-center gap-2 mb-5 justify-between"
}
const _hoisted_23 = { class: "text-base" }
const _hoisted_24 = {
  key: 0,
  class: "line-through text-red-700 me-2"
}
const _hoisted_25 = { class: "text-green-700 font-bold text-lg" }
const _hoisted_26 = { class: "uppercase" }
const _hoisted_27 = { class: "lowercase" }
const _hoisted_28 = { class: "me-auto text-lg text-green-700 font-bold" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "text-base" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { class: "w-full lg:min-w-96 lg:max-w-96" }
const _hoisted_35 = { class: "border rounded p-5 shadow-md w-full h-full flex flex-col" }
const _hoisted_36 = { class: "p-2 text-xl font-bold" }
const _hoisted_37 = { class: "my-5 p-5 text-base text-left border rounded" }
const _hoisted_38 = { class: "font-bold mb-2" }
const _hoisted_39 = { class: "mt-auto" }
const _hoisted_40 = { class: "flex items-center gap-2 mb-5 justify-between" }
const _hoisted_41 = {
  key: 0,
  class: "text-md text-left w-full border-2 border-red-700 rounded p-5"
}
const _hoisted_42 = { class: "font-bold text-2xl mb-5 text-center" }
const _hoisted_43 = ["innerHTML"]
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "mt-4 text-2xl font-semibold text-gray-900" }
const _hoisted_47 = { class: "text-base text-gray-600" }
const _hoisted_48 = { class: "text-base text-gray-600" }
const _hoisted_49 = { class: "flex justify-center items-center gap-2" }
const _hoisted_50 = {
  href: "/#/auth/signup",
  class: "primary mt-4 inline-block px-4 py-2 text-lg text-white bg-primary-600 rounded hover:bg-primary-700"
}
const _hoisted_51 = {
  href: "/#/contact",
  class: "primary mt-4 inline-block px-4 py-2 text-lg text-white bg-primary-600 rounded hover:bg-primary-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('pricing.header')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('pricing.text')), 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "w-full lg:min-w-96 lg:max-w-96"
          }, [
            (product.prices && product.prices.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(product.name), 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('description')) + ":", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t(`pricing.description${index}`)), 1)
                  ]),
                  (product.prices && product.prices.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('pricing.prices')) + ":", 1),
                        (!!_ctx.trial && _ctx.trial.trial_weeks > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("div", {
                                innerHTML: _ctx.$t('pricing.free_trial', { weeks: _ctx.trial ? _ctx.trial.trial_weeks : 0})
                              }, null, 8, _hoisted_13),
                              _createElementVNode("div", {
                                innerHTML: _ctx.$t('pricing.trial_for_branch', { weeks: _ctx.trial ? _ctx.trial.trial_weeks : 0})
                              }, null, 8, _hoisted_14),
                              _createElementVNode("div", {
                                innerHTML: _ctx.$t('pricing.trial_start', { weeks: _ctx.trial ? _ctx.trial.trial_weeks : 0})
                              }, null, 8, _hoisted_15),
                              _createElementVNode("div", {
                                innerHTML: _ctx.$t('pricing.payments_start', { weeks: _ctx.trial ? _ctx.trial.trial_weeks : 0})
                              }, null, 8, _hoisted_16)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.getPrice(product, 'month'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createTextVNode(_toDisplayString(_ctx.getPrice(product, 'month').unit_amount / 100) + " ", 1),
                                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.getPrice(product, 'month').currency), 1),
                                _createElementVNode("span", _hoisted_20, " / " + _toDisplayString(_ctx.$t('month')) + " / " + _toDisplayString(_ctx.$t('branch')), 1)
                              ]),
                              _createElementVNode("button", {
                                class: "primary",
                                onClick: ($event: any) => (_ctx.$router.push({ path: '/auth/signup', query: { price: _ctx.getPrice(product, 'month').id } }))
                              }, _toDisplayString(_ctx.$t('choose')), 9, _hoisted_21)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.getPrice(product, 'year'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                (_ctx.getPrice(product, 'month'))
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.getPrice(product, 'month').unit_amount * 0.12), 1))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.getPrice(product, 'year').unit_amount / 100), 1),
                                _createTextVNode(),
                                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.getPrice(product, 'year').currency), 1),
                                _createElementVNode("span", _hoisted_27, " / " + _toDisplayString(_ctx.$t('year')) + " / " + _toDisplayString(_ctx.$t('branch')), 1)
                              ]),
                              _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('pricing.save')) + " " + _toDisplayString((_ctx.getPrice(product, 'month').unit_amount * 12 - _ctx.getPrice(product, 'year').unit_amount) / 100), 1),
                              _createElementVNode("button", {
                                class: "primary",
                                onClick: ($event: any) => (_ctx.$router.push({ path: '/auth/signup', query: { price: _ctx.getPrice(product, 'year').id } }))
                              }, _toDisplayString(_ctx.$t('choose')), 9, _hoisted_29)
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOtherPrices(product), (price, index2) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index2,
                            class: "flex items-center gap-2 mb-5 justify-between"
                          }, [
                            _createElementVNode("div", _hoisted_30, [
                              _createTextVNode(_toDisplayString(price.unit_amount / 100) + " " + _toDisplayString(price.currency), 1),
                              (price.recurring)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_31, " / " + _toDisplayString(price.recurring.interval), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("button", {
                              class: "primary",
                              onClick: ($event: any) => (_ctx.$router.push({ path: '/auth/signup', query: { price: price.id } }))
                            }, _toDisplayString(_ctx.$t('choose')), 9, _hoisted_32)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(_ctx.$t('pricing.notAvailable')), 1))
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t('pricing.enterpriceProduct.name')), 1),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t('description')) + ":", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('pricing.enterpriceProduct.description')), 1)
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("button", {
                  class: "primary mx-auto",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ path: '/contact' })))
                }, _toDisplayString(_ctx.$t('nav.contactNav')), 1)
              ])
            ])
          ])
        ])
      ]),
      (_ctx.otherDiscounts && _ctx.otherDiscounts.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t('discounts.title')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherDiscounts, (discount, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("div", {
                  innerHTML: _ctx.$t(discount.description, { percent: discount.percentValue, static: discount.staticValue })
                }, null, 8, _hoisted_43),
                (discount.endDateToJoin)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      innerHTML: _ctx.$t('discounts.valid', { validTo: _ctx.formatDate(new Date(discount.endDateToJoin)) })
                    }, null, 8, _hoisted_44))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.trial && _ctx.trial.trial_weeks > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
            _createElementVNode("h2", _hoisted_46, _toDisplayString(_ctx.$t('pricing.button_header')), 1),
            _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.$t('pricing.trial', { weeks: _ctx.trial.trial_weeks})), 1),
            _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.$t('pricing.consultations')), 1),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("a", _hoisted_50, _toDisplayString(_ctx.$t('nav.buttonStart', { weeks: _ctx.trial.trial_weeks})), 1),
              _createElementVNode("a", _hoisted_51, _toDisplayString(_ctx.$t('pricing.consultation_button')), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}